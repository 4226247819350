import { BrowserModule, HammerGestureConfig } from '@angular/platform-browser';
import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './shared/error.interceptor';
import { AuthInterceptor } from './shared/auth-interceptor';
import { BehaviorService } from './shared/behavior.service';
import { RouterModule } from '@angular/router';
import {SharedModule} from './shared/shared/shared.module'; 
import { AuthService } from './auth/auth.service';
import { SocketIoModule, SocketIoConfig } from "ngx-socket-io";
import { CommonService } from './services/common.service';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ThemeService } from 'ng2-charts';
import { ContactCardComponent } from './contact-card/contact-card.component';
import { environment } from 'src/environments/environment';

 

// const URL = "http://35.183.188.110:3003/";
// const URL = "http://74.208.206.18:4003"; 
const URL = environment.chatUrl;

// const URL = "http://198.251.65.146:4504/";
const config: SocketIoConfig = { url: URL, options: { autoConnect : false } };
export class CustomHammerConfig extends HammerGestureConfig {
  overrides = {
      pan: {
          direction: 6 // this code enables only horizontal direction
      },
      pinch: {
          enable: false
      },
      rotate: {
          enable: false
      }
  };
}

@NgModule({
  declarations: [
    AppComponent,
    ContactCardComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
 
    ToastrModule.forRoot({ 
      maxOpened:1, 
      preventDuplicates: true,
      timeOut:3000

   }),
  //  SocketIoModule.forRoot({url:"http://74.208.206.18:4003"}), 
   SocketIoModule.forRoot({url:environment.chatUrl}), 

    BrowserAnimationsModule,
    HttpClientModule,SharedModule, CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory })
  ],
  providers: [
    BehaviorService,
    ThemeService,
    AuthService,
    CommonService,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: CustomHammerConfig
  }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
